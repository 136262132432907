.circle3 {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    /*padding: 10px;*/
    background: #ff8f43;
    /*border: 3px solid #26c1db;*/
    color: #fff;
    text-align: center;
    font-size: 80px;
    margin-top: -60px;
}

.step-card {
    align-items: center;
    border: 0px;
    margin-bottom: 80px;
}