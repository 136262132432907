// Global settings

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Color system

$white: #fff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default; // Line footer color
$gray-700: #525f7f !default; // Line p color
$gray-800: #32325d !default; // Line heading color
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #5e72e4 !default;
$indigo: #5603ad !default;
$purple: #8965e0 !default;
$pink: #f3a4b5 !default;
$red: #f5365c !default;
$orange: #fb6340 !default;
$yellow: #ffd600 !default;
$green: #2dce89 !default;
$teal: #11cdef !default;
$cyan: #2bffc6 !default;

$colors: () !default;
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'light': $gray-400,
    'lighter': $gray-200,
    'gray-dark': $gray-800,
  ),
  $colors
);

$default: #172b4d !default;
$primary: #5e72e4 !default;
$secondary: #f4f5f7 !default;
$success: $green !default;
$info: $teal !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-900 !default;
$darker: darken($gray-900, 15%) !default;

$facebook: #3b5999 !default;
$twitter: #1da1f2 !default;
$google-plus: #dd4b39 !default;
$instagram: #e4405f !default;
$pinterest: #bd081c !default;
$youtube: #cd201f !default;
$slack: #3aaf85 !default;
$dribbble: #ea4c89 !default;
$github: #222222 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'default': $default,
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'white': $white,
    'neutral': $white,
    'dark': $dark,
    'darker': $darker,
  ),
  $theme-colors
);

$brand-colors: () !default;
$brand-colors: map-merge(
  (
    'facebook': $facebook,
    'twitter': $twitter,
    'google-plus': $google-plus,
    'instagram': $instagram,
    'pinterest': $pinterest,
    'youtube': $youtube,
    'slack': $slack,
    'dribbble': $dribbble,
    'github': $github,
  ),
  $brand-colors
);

$shape-colors: () !default;
$shape-colors: map-merge(
  (
    'default': #32325d,
    'primary': #5533ff,
    'secondary': #24b47e,
    'neutral': #e9ecef,
    'blue-gray': #b2cbe1,
  ),
  $shape-colors
);

$shapes-primary-colors: () !default;
$shapes-primary-colors: map-merge(
  (
    'step-1-gradient-bg': #281483,
    'step-2-gradient-bg': #8f6ed5,
    'step-3-gradient-bg': #d782d9,
    'span-1-bg': #53f,
    'span-2-bg': #4553ff,
    'span-3-bg': #4f40ff,
    'span-4-bg': #25ddf5,
    'span-5-bg': #1fa2ff,
  ),
  $shapes-primary-colors
);

$shapes-default-colors: () !default;
$shapes-default-colors: map-merge(
  (
    'step-1-gradient-bg': #7795f8,
    'step-2-gradient-bg': #6772e5,
    'step-3-gradient-bg': #555abf,
    'span-1-bg': #7795f8,
    'span-2-bg': #7b9aff,
    'span-3-bg': #6f8ff8,
    'span-4-bg': #76eea7,
    'span-5-bg': #6adaff,
  ),
  $shapes-default-colors
);

$shapes-light-colors: () !default;
$shapes-light-colors: map-merge(
  (
    'step-1-gradient-bg': #b2cbe1,
    'step-2-gradient-bg': #f6f9fc,
    'step-3-gradient-bg': #f6f9fc,
    'span-1-bg': #b4cce1,
    'span-2-bg': #c5dbef,
    'span-3-bg': #b9d5ed,
    'span-4-bg': #74e4a2,
    'span-5-bg': #008169,
  ),
  $shapes-light-colors
);

$shapes-dark-colors: () !default;
$shapes-dark-colors: map-merge(
  (
    'step-1-gradient-bg': #32325d,
    'step-2-gradient-bg': #32325d,
    'step-3-gradient-bg': #32325d,
    'span-1-bg': #2e2e57,
    'span-2-bg': #2b2b58,
    'span-3-bg': #25254d,
    'span-4-bg': #d782d9,
    'span-5-bg': #008169,
  ),
  $shapes-dark-colors
);

// Translucent color variations
$translucent-color-opacity: 0.6;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Action colors

$star-rating-color: $gray-400 !default;
$star-rating-color-active: $yellow !default;

$favorite-color: $yellow !default;
$like-color: $blue !default;
$love-color: $red !default;

// Body

$body-bg: $white !default;
$body-color: $gray-700 !default;

// Sections

$section-colors: () !default;
$section-colors: map-merge(
  (
    'primary': $body-bg,
    'secondary': $secondary,
    'light': $gray-400,
    'dark': $dark,
    'darker': $darker,
  ),
  $section-colors
);

// Links

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none !default;

// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1040px,
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Spacing

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    -9: -($spacer * 10),
    -8: -($spacer * 8),
    -7: -($spacer * 6),
    -6: -($spacer * 4.5),
    -5: -($spacer * 3),
    -4: -($spacer * 1.5),
    -3: -$spacer,
    -2: -($spacer * 0.5),
    -1: -($spacer * 0.25),
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4.5),
    7: ($spacer * 6),
    8: ($spacer * 8),
    9: ($spacer * 10)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.

$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
  ),
  $sizes
);

// Components

$shape-height-xl: 1.5 !default;
$shape-height-lg: 1.5 !default;
$shape-height-sm: 1.5 !default;

$border-width: 0.0625rem !default;
$border-color: $gray-200 !default;

$border-radius: 0.25rem !default;
$border-radius-xl: 0.35rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

$component-active-color: $white !default;
$component-active-bg: theme-color('primary') !default;
$component-active-border-color: theme-color('primary') !default;

$component-hover-color: $gray-300 !default;
$component-hover-bg: $gray-300 !default;
$component-hover-border-color: $gray-300 !default;

$caret-width: 0.3em !default;

$transition-base: all 0.15s ease !default;
$btn-transition: $transition-base !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Fonts

$font-family-base: 'Open Sans', sans-serif !default;
$font-family-alt: 'Open Sans', sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xl: ($font-size-base * 1.5);
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);
$font-size-xs: ($font-size-base * 0.75);

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-extra-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$shape-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1.5 !default;
$headings-color: $gray-800 !default;

$heading-letter-spacing: 0.025em !default;
$heading-font-size: 0.95rem !default;
$heading-text-transform: uppercase !default;
$heading-font-weight: $headings-font-weight !default;

$heading-title-letter-spacing: 0.025em !default;
$heading-title-font-size: 1.375rem !default;
$heading-title-font-weight: $font-weight-bold !default;
$heading-title-text-transform: uppercase !default;

$heading-section-letter-spacing: 0.025em !default;
$heading-section-font-size: 1.375rem !default;
$heading-section-font-weight: $font-weight-bold !default;
$heading-section-text-transform: uppercase !default;

$display1-size: 3.3rem !default;
$display2-size: 2.75rem !default;
$display3-size: 2.1875rem !default;
$display4-size: 1.6275rem !default;

$display1-weight: $font-weight-bold !default;
$display2-weight: $font-weight-bold !default;
$display3-weight: $font-weight-bold !default;
$display4-weight: $font-weight-bold !default;
$display-line-height: $headings-line-height !default;

$paragraph-font-size: 1rem !default;
$paragraph-font-weight: 300 !default;
$paragraph-line-height: 1.7 !default;

$lead-font-size: ($paragraph-font-size * 1.25);
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer * 2 !default;

// Icons

$icon-size: 3rem !default;
$icon-size-xl: 5rem !default;
$icon-size-lg: 4rem !default;
$icon-size-sm: 2rem !default;
$icon-size-xs: 1.25rem !default;

// Tables

$table-cell-padding: 1rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-bg: transparent !default;
$table-accent-bg: rgba($black, 0.05);
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-300 !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-bg: $gray-900 !default;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg !default;

// Buttons + Forms

$input-btn-padding-y: 0.625rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-line-height: $shape-height-base !default;

$input-btn-focus-width: 0 !default;
$input-btn-focus-color: rgba($component-active-bg, 1);
//$input-btn-focus-box-shadow:  0 4px 10px 0 rgba(0, 0, 0, 0.04);
$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-line-height-sm: $shape-height-sm !default;

$input-btn-padding-y-lg: 0.875rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height-lg: $shape-height-lg !default;

$input-btn-border-width: 1px !default;

$input-btn-font-size-sm: 0.75rem !default;
$input-btn-font-size: 0.875rem !default;
$input-btn-font-size-lg: 0.875rem !default;

// Buttons

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x + 0.5 !default;
$btn-line-height: $input-btn-line-height !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: 600 !default;
$btn-text-transform: uppercase !default;
$btn-letter-spacing: 0.025em !default;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
  0 3px 6px rgba(0, 0, 0, 0.08);
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-focus-width: $input-btn-focus-width !default;

$btn-active-box-shadow: none !default;

// Forms

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-border-radius: $border-radius !default;
$input-border-radius-xl: $border-radius-xl !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;

$input-muted-bg: #edf0f5 !default;

$input-alternative-box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15),
  0 1px 0 rgba(0, 0, 0, 0.02);
$input-focus-alternative-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);

$input-color: $gray-600 !default;
$input-border-color: #cad1d7 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: none !default; // 0 1px 3px 0 $gray-400 !default;

$input-focus-bg: $white !default;
$input-focus-border-color: rgba(50, 151, 211, 0.25);
$input-focus-color: $input-color !default;
$input-focus-width: 0 !default;
$input-focus-box-shadow: none !default; //0 1px 3px 0 $gray-500 !default;

$input-placeholder-color: $gray-500 !default;
$input-focus-placeholder-color: $gray-500 !default;

$input-height-border: $input-border-width * 2 !default;

$input-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

// Input groups

$input-group-addon-color: $input-placeholder-color !default;
$input-group-addon-bg: $input-bg !default;
$input-group-addon-border-color: $input-border-color !default;

$input-group-addon-focus-color: $input-focus-color !default;
$input-group-addon-focus-bg: $input-focus-bg !default;
$input-group-addon-focus-border-color: $input-focus-border-color !default;

// Custom forms

$custom-control-gutter: 1.75rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-indicator-size: 1.25rem !default;

$custom-control-indicator-bg: $input-bg !default;
$custom-control-indicator-border-width: 1px !default;
$custom-control-indicator-border-color: $input-border-color !default;
$custom-control-indicator-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: $custom-control-indicator-box-shadow !default;

$custom-control-indicator-hover-color: $component-hover-color !default;
$custom-control-indicator-hover-bg: $component-hover-bg !default;
$custom-control-indicator-hover-border-color: $component-hover-border-color !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: $component-active-bg !default;
$custom-control-indicator-active-border-color: $component-active-border-color !default;
$custom-control-indicator-active-box-shadow: $custom-control-indicator-box-shadow !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-border-color: $component-active-border-color !default;
$custom-control-indicator-checked-box-shadow: $custom-control-indicator-box-shadow !default;
$custom-control-indicator-checked-disabled-bg: rgba(
  theme-color('primary'),
  0.5
);

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-checkbox-indicator-border-radius: $border-radius-sm !default;
//$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-toggle-slider-bg: #ddd !default;
$custom-toggle-checked-bg: theme-color('primary') !default;

// Form validation

$form-feedback-valid-bg: lighten($success, 15%);
$form-feedback-valid-color: theme-color('success') !default;
$form-feedback-invalid-bg: lighten($warning, 15%);
$form-feedback-invalid-color: theme-color('warning') !default;

// Allows for customizing button radius independently from global border radius

$btn-border-radius: $input-border-radius !default;
$btn-border-radius-xl: $input-border-radius-xl !default;
$btn-border-radius-lg: $input-border-radius-lg !default;
$btn-border-radius-sm: $input-border-radius !default;

// No UI Slider

$noui-target-bg: #eceeef !default;
$noui-target-thickness: 5px !default;
$noui-target-border-radius: 5px !default;
$noui-target-border-color: 0 !default;
$noui-target-box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);

$noui-slider-connect-bg: $primary !default;
$noui-slider-connect-disabled-bg: #b2b2b2 !default;

$noui-handle-width: 15px !default;
$noui-handle-bg: theme-color('primary') !default;
$noui-handle-border: 0 !default;
$noui-handle-border-radius: 100% !default;

$noui-origin-border-radius: 2px !default;

// Dropdown

$dropdown-bg: $white !default;
$dropdown-border-width: 0 !default;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius-lg !default;
$dropdown-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
  0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

// Navs

$nav-link-padding-y: 0.25rem !default;
$nav-link-padding-x: 0.75rem !default;
$nav-link-color: $gray-700 !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-pills-padding-y: 0.75rem !default;
$nav-pills-padding-x: 1rem !default;

$nav-pills-space-x: 1rem !default;

$nav-pills-bg: $white;
$nav-pills-border-width: 1px !default;
$nav-pills-border-color: theme-color('primary') !default;
$nav-pills-border-radius: $border-radius !default;

$nav-pills-link-color: theme-color('primary') !default;
$nav-pills-link-hover-color: darken(theme-color('primary'), 5%);
$nav-pills-link-active-color: color-yiq(theme-color('primary'));
$nav-pills-link-active-bg: theme-color('primary') !default;
$nav-pills-box-shadow: $btn-box-shadow !default;

// Navbar

$navbar-transition: all 0.15s linear !default;
$navbar-padding-y: 1rem !default;
$navbar-padding-x: 1rem !default;

$navbar-nav-link-padding-x: 1rem !default;
$navbar-nav-link-padding-y: 1rem !default;

$navbar-nav-link-font-family: $font-family-alt !default;
$navbar-nav-link-font-size: 0.9rem !default;
$navbar-nav-link-font-weight: 400 !default;
$navbar-nav-link-text-transform: normal !default;
$navbar-nav-link-letter-spacing: 0 !default;
$navbar-nav-link-border-radius: $border-radius !default;

$navbar-dark-bg: transparent !default;
$navbar-dark-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-active-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-color: rgba($white, 0.95);
$navbar-dark-hover-color: rgba($white, 0.65);
$navbar-dark-active-color: rgba($white, 0.65);
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-border-color: transparent !default;

$navbar-light-bg: transparent !default;
$navbar-light-hover-bg: rgba(0, 0, 0, 0.1);
$navbar-light-active-bg: rgba(0, 0, 0, 0.1);
$navbar-light-border-color: #f3f3f3;
$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
//$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml !default;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: transparent !default;

// Alerts

$alert-padding-y: 1rem !default;
$alert-padding-x: 1.5rem !default;
$alert-border-radius: $border-radius !default;

$alert-bg-level: -2 !default;
$alert-border-level: -2 !default;
$alert-color-level: 0 !default;

// List group

$list-group-bg: $white !default;
$list-group-border-color: $border-color !default; //rgba($black, .125);
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: 1rem !default;
$list-group-item-padding-x: 1rem !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $list-group-action-color !default;
$list-group-action-active-bg: $gray-200 !default;

// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-bg: transparent !default;
$close-hover-bg: transparent !default;
$close-color: rgba(0, 0, 0, 0.6);
$close-hover-color: rgba(0, 0, 0, 0.9);
$close-text-shadow: none !default;

// Popovers

$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: 1px !default;
$popover-border-color: rgba($black, 0.05);
$popover-border-radius: $border-radius-lg !default;
$popover-box-shadow: 0px 0.5rem 2rem 0px rgba($black, 0.2);

$popover-header-bg: $popover-bg !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.75rem !default;
$popover-header-padding-x: 0.75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: 1.5rem !default;
$popover-arrow-height: 0.75rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: transparent !default;

// Badges

$badge-font-size: 66% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: 0.35rem !default;
$badge-padding-x: 0.375rem !default;
$badge-border-radius: $border-radius !default;
$badge-text-transfom: uppercase;

$badge-pill-padding-x: 0.875em !default;
$badge-pill-border-radius: 10rem !default;

$badge-circle-size: 2rem;

// Pagination

// $pagination-padding-y:              .5rem !default;
// $pagination-padding-x:              .75rem !default;
// $pagination-padding-y-sm:           .25rem !default;
// $pagination-padding-x-sm:           .5rem !default;
// $pagination-padding-y-lg:           .75rem !default;
// $pagination-padding-x-lg:           1.5rem !default;
// $pagination-line-height:            1.25 !default;

$pagination-color: $gray-600 !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: $gray-300 !default;

//$pagination-focus-box-shadow:       $btn-hover-box-shadow !default;

$pagination-hover-color: $gray-600 !default;
$pagination-hover-bg: $gray-300 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;
$pagination-active-box-shadow: $btn-hover-box-shadow !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

// Cards

$card-spacer-y: 1.25rem !default;
$card-spacer-x: 1.5rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, 0.05);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $gray-100 !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

//$card-group-margin:                 ($grid-gutter-width / 2);
//$card-deck-margin:                  $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;

// Modals

$modal-inner-padding: 1.5rem !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 380px !default;

$modal-title-line-height: 1.1;

//$modal-title-line-height:           $shape-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2);
$modal-content-border-width: 1px !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-box-shadow-xs: 0 15px 35px rgba(50, 50, 93, 0.2),
  0 5px 15px rgba(0, 0, 0, 0.17);
$modal-content-box-shadow-sm-up: 0 15px 35px rgba(50, 50, 93, 0.2),
  0 5px 15px rgba(0, 0, 0, 0.17);

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.16 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1.25rem !default;

// Datepicker

$datepicker-border-radius: $card-border-radius !default;
$datepicker-dropdown-padding: 20px 22px !default;

$datepicker-cell-transition: $transition-base !default;
$datepicker-cell-hover-background: lighten($gray-400, 55%);
$datepicker-cell-border-radius: 50% !default;
$datepicker-cell-width: 36px !default;
$datepicker-cell-height: 36px !default;

$datepicker-disabled-cell-color: $gray-300 !default;
$datepicker-disabled-old-new-color: $gray-500 !default;

$datepicker-header-cell-border-radius: $border-radius !default;

$datepicker-active-color: $white !default;
$datepicker-active-background: theme-color('primary') !default;
$datepicker-active-box-shadow: none !default;

$datepicker-range-background: theme-color('primary') !default;
$datepicker-range-cell-focused-background: darken(
  $datepicker-range-background,
  5%
);
$datepicker-range-color: $white !default;
$datepicker-range-highlighted-bg: $gray-200 !default;

$datepicker-dropdown-border: lighten($gray-400, 40%);
$datepicker-dropdown-bg: $white !default;
$datepicker-highlighted-bg: $datepicker-active-background !default;

// Footer

$footer-link-font-size: 0.85rem !default;
$footer-bg: theme-color('secondary') !default;
$footer-color: $gray-600 !default;
$footer-link-color: $gray-600 !default;
$footer-link-hover-color: $gray-700 !default;
$footer-heading-color: $gray-600 !default;
$footer-heading-font-size: $font-size-sm !default;
