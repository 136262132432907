.navbar-yetky img {
  height: 55px;
}

.yetky-card-cover-main {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.yetky-card-cover {
  z-index: 100;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 1;
  border-radius: 0.25rem;
}

.yetky-card-cover-spinner {
  margin: auto;
  color: #ff8f43 !important;
}

.yetky-page-not-found {
  img {
    width: 100%;
  }
}

.main-wrapper {
  min-height: calc(100vh - 137px);
}

.nav-footer {
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .yetky-page-not-found {
    img {
      margin-top: 150px;
    }
  }

  .yetky-pb-sm-3 {
    padding-bottom: 3rem;
  }

  .yetky-xs-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .copyright {
    text-align: center;
  }

  .nav-footer {
    justify-content: center;
  }
}
